/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 12px !important;
}

.pointer {
    cursor: pointer !important;
}
.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}


.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input
{
  color: #000000 !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled
{
  background-color: #f5f5f5;
}

// Ajustes menu mobile
.caption-mobile, .logo-mobile {
  display: none;
}

.logo-mobile {
  margin-left: -7px;
}

.sidebarNav-mini .sidebarNav:hover {
  .caption-normal, .logo-desktop {
    display: block !important;
  }

  .caption-mobile, .logo-mobile {
    display: none !important;
  }
}

.sidebarNav-mini {
  .caption-normal, .logo-desktop {
    display: none !important;
  }

  .caption-mobile, .logo-mobile {
    display: block !important;
  }
}

//Fim ajuste menu mobile



