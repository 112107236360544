// theme
.mat-drawer-side {
  border-right: 1px solid $borderColor;
  -webkit-box-shadow: 4px 0px 29px -16px rgba(0, 0, 0, 0.20) !important;
  -moz-box-shadow: 4px 0px 29px -16px rgba(0, 0, 0, 0.20) !important;
  box-shadow: 4px 0px 29px -16px rgba(0, 0, 0, 0.20) !important;
}

.mat-divider {
  border-top-color: $borderColor;
}